var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-dialog',{attrs:{"persistent":"","max-width":"500px","transition":"slide-y-transition"},model:{value:(_vm.addEditDialog),callback:function ($$v) {_vm.addEditDialog=$$v},expression:"addEditDialog"}},[_c('v-card',{staticClass:"dialog-card-border"},[_c('v-card-title',{staticClass:"text-h6 font-weight-light"},[_vm._v(" "+_vm._s(_vm.editMode ? _vm.$t('Update Receipt') : _vm.$t('Add New Receipt'))+" ")]),_c('validation-observer',{ref:"theForm",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
var reset = ref.reset;
return [_c('form',{attrs:{"autocomplete":"off"},on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.submit)},"reset":function($event){$event.preventDefault();return reset.apply(null, arguments)}}},[_c('v-card-text',[_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',{staticClass:"px-2 text-subtitle-1 mb-6 font-weight-bold",attrs:{"cols":"12"}},[_vm._v(" "+_vm._s(_vm.$t('Customer Balance'))+": "+_vm._s(_vm.$_format_number(_vm.formData.customer_balance))+" ")]),_c('v-col',{staticClass:"px-1",attrs:{"cols":"12"}},[_c('validation-provider',{attrs:{"name":_vm.$t('Customer'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-autocomplete',{attrs:{"label":("* " + (_vm.$t('Customer'))),"items":_vm.customersList,"item-text":function (item) { return ((item.name) + " | " + (item.phone1) + " | " + (item.phone2)); },"item-value":function (item) { return item.id; },"outlined":"","dense":"","color":_vm.$_input_color(),"error-messages":errors},on:{"input":function($event){return _vm.customerSelected(_vm.formData.customer)}},model:{value:(_vm.formData.customer),callback:function ($$v) {_vm.$set(_vm.formData, "customer", $$v)},expression:"formData.customer"}})]}}],null,true)})],1),_c('v-col',{staticClass:"px-1",attrs:{"cols":"12"}},[_c('validation-provider',{attrs:{"name":_vm.$t('Amount'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-currency-field',{attrs:{"label":("* " + (_vm.$t('Amount'))),"outlined":"","dense":"","color":_vm.$_input_color(),"error-messages":errors},model:{value:(_vm.formData.amount),callback:function ($$v) {_vm.$set(_vm.formData, "amount", $$v)},expression:"formData.amount"}})]}}],null,true)})],1),_c('v-col',{staticClass:"px-1",attrs:{"cols":"12"}},[_c('validation-provider',{attrs:{"name":_vm.$t('Note')},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-textarea',{attrs:{"label":_vm.$t('Note'),"outlined":"","dense":"","rows":"3","color":_vm.$_input_color(),"error-messages":errors},model:{value:(_vm.formData.note),callback:function ($$v) {_vm.$set(_vm.formData, "note", $$v)},expression:"formData.note"}})]}}],null,true)})],1),_c('v-col',{staticClass:"px-1",attrs:{"cols":"12"}},[_c('v-menu',{ref:"actionDate",attrs:{"transition":"scale-transition","offset-y":"","min-width":"auto"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"label":_vm.$t('Date'),"prepend-inner-icon":"mdi-calendar","readonly":"","outlined":"","dense":""},model:{value:(_vm.formData.action_date),callback:function ($$v) {_vm.$set(_vm.formData, "action_date", $$v)},expression:"formData.action_date"}},'v-text-field',attrs,false),on))]}}],null,true),model:{value:(_vm.dateMenu),callback:function ($$v) {_vm.dateMenu=$$v},expression:"dateMenu"}},[_c('v-date-picker',{attrs:{"scrollable":"","no-title":""},on:{"input":function($event){_vm.dateMenu = false}},model:{value:(_vm.formData.action_date),callback:function ($$v) {_vm.$set(_vm.formData, "action_date", $$v)},expression:"formData.action_date"}})],1)],1)],1)],1),_c('v-divider'),_c('v-card-actions',{staticClass:"py-3"},[_c('app-form-tip'),_c('v-spacer'),_c('v-btn',{attrs:{"depressed":"","outlined":"","small":""},on:{"click":function($event){return _vm.closeDialog()}}},[_vm._v(" "+_vm._s(_vm.$t('Cancel'))+" ")]),_c('app-submit-button',{attrs:{"edit-mode":_vm.editMode}})],1)],1)]}}])})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }